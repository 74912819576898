.table-cell {
  vertical-align: middle !important;
  text-align: center;
}

.edit-icon {
  color: #000000;
}

.delete-icon {
  color: #ff0000;
}

.add-weapon-button {
  text-align: center;
  margin-bottom: 2em;
}

.weapon-image {
  width: 100px;
}

.weapon-fire {
  box-sizing: border-box;
  padding: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgb(0 0 0 / 0.03);
  border-radius: 5px;
}
