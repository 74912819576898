.table-cell {
  vertical-align: middle !important;
}

.edit-icon {
  color: #000000;
}

.delete-icon {
  color: #ff0000;
}

.add-agent-button {
  text-align: center;
  margin-bottom: 2em;
}

.agent-ability {
  box-sizing: border-box;
  padding: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgb(0 0 0 / 0.03);
  border-radius: 5px;
}
.agent-ability:not(:last-of-type) {
  margin-bottom: 2rem;
}

.table-cell {
  vertical-align: middle !important;
  text-align: center;
}

.edit-icon {
  color: #000000;
}

.delete-icon {
  color: #ff0000;
}

.add-weapon-button {
  text-align: center;
  margin-bottom: 2em;
}

.weapon-image {
  width: 100px;
}

.weapon-fire {
  box-sizing: border-box;
  padding: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgb(0 0 0 / 0.03);
  border-radius: 5px;
}

.add-map-button {
  text-align: center;
  margin-bottom: 2em;
}

.card {
  text-align: center;
  background-color: black;
}

.delete-map-icon {
  text-align: right;
}

.table-cell {
  vertical-align: middle !important;
}

.add-tip-button {
  text-align: center;
  margin-bottom: 2em;
}

.map-frame-outer {
  max-width: 600px;
}
.map-frame {
  position: relative;
  height: 0;
  padding-bottom: 100%;
}
.map-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  cursor: crosshair;
}
.dot {
  position: absolute;
  height: 7px;
  width: 7px;
  background: red;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.map-list {
  display: flex;
}
.map-list button {
  border: none;
  outline: none;
  padding: 4px 16px;
  cursor: pointer;
}
.map-list button.active {
  background: red;
  color: white;
}
.radio-select {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.radio-select--btn {
  display: flex;
  align-items: center;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0 1rem;
  border: 1px solid #ced4da;
  cursor: pointer;
}
.radio-select--btn.checked {
  background: #e7eaec;
}
.radio-select label {
  display: block;
  margin: 0;
  margin-left: 0.5rem;
  padding-top: 0.125rem;
  cursor: pointer;
}
.ytplayer {
  margin-top: 1rem;
  border-radius: 5px;
  box-shadow: 0 14px 16px rgb(0 0 0 / 36%);
}
.yt-id-exmaple {
  color: rgba(27, 40, 56, 0.75);
}
.yt-id-exmaple span {
  text-decoration: underline;
  font-weight: 800;
  color: black;
}

.table-cell {
  vertical-align: middle !important;
}

.add-tip-button {
  text-align: center;
  margin-bottom: 2em;
}

select {
  min-width: 150px;
}

.dot {
  position: absolute;
  height: 7px;
  width: 7px;
  background: red;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.dot.red {
  background: red;
}

.dot.blue {
  background: blue;
}

.clip {
  position: absolute;
  border: 3px solid green;
}

.weapon-group {
  padding: 0 25px 0 15px;
  margin-bottom: 20px;
}

.weapon-group h6 {
  margin-bottom: 20px;
}

.weapon-item {
  height: 40px;
  width: 200px;
  display: flex;
  align-items: center;
  margin: 10px;
  cursor: pointer;
}

.weapon-item:hover svg path,
.weapon-item.selected svg path {
  fill: #182338;
}

.weapon-item:hover h6,
.weapon-item.selected h6 {
  color: #182338;
  font-weight: bold;
}

.weapon-item h6 {
  padding: 0;
  margin-bottom: 0;
  color: #73767d;
}

.weapon-name {
  margin-left: 20px;
}

.map-frame-outer {
  width: 100%;
  max-width: 600px;
}

.map-frame {
  position: relative;
  height: 0;
  padding-bottom: 100%;
}

.map-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  cursor: crosshair;
}

.control-map-container {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.switch-control-form {
  border: 1px solid #73767d;
  border-radius: 5px;
  padding: 10px 15px 2px;
  margin-bottom: 20px;
}

.radio input {
  margin-right: 10px;
}

.color-red {
  color: red;
}

.color-blue {
  color: blue;
}

.wrong-text {
  margin: 20px 0;
  color: red;
}

html {
  background-color: rgba(247, 247, 248, 1.0);
}
.hvr-box-background:hover {
  background: rgba(247, 247, 248, 1.0) !important;
}

html {
  background-color: rgba(247, 247, 248, 1.0);
}

@font-face {
  font-family: Maison Neue;
  src: url('/assets/fonts/MaisonNeue-Book.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Maison Neue;
  src: url('/assets/fonts/MaisonNeue-Demi.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

html {
  height: 100%;
  width: 100%;
}
body {
  font-family: Maison Neue, Sans-Serif;
  min-height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}
#root {
  height: 100%;
  width: 100%;
}

.hvr-bg-red:hover {
  background-color: rgba(243, 83, 83, 1) !important;
}
.hvr-color-red:hover {
  color: rgba(243, 83, 83, 1) !important;
}
.hvr-bg-gray:hover {
  background-color: rgba(27, 40, 56, 0.1) !important;
}
.hvr-color-gray:hover {
  color: rgba(27, 40, 56, 0.1) !important;
}
a {
  text-decoration: none;
}

.row {
  margin-bottom: 15px;
}

.add-content-list-item-button {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  margin-bottom: 2em;
}

