@font-face {
  font-family: Maison Neue;
  src: url('/assets/fonts/MaisonNeue-Book.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Maison Neue;
  src: url('/assets/fonts/MaisonNeue-Demi.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

html {
  height: 100%;
  width: 100%;
}
body {
  font-family: Maison Neue, Sans-Serif;
  min-height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}
#root {
  height: 100%;
  width: 100%;
}

.hvr-bg-red:hover {
  background-color: rgba(243, 83, 83, 1) !important;
}
.hvr-color-red:hover {
  color: rgba(243, 83, 83, 1) !important;
}
.hvr-bg-gray:hover {
  background-color: rgba(27, 40, 56, 0.1) !important;
}
.hvr-color-gray:hover {
  color: rgba(27, 40, 56, 0.1) !important;
}
a {
  text-decoration: none;
}

.row {
  margin-bottom: 15px;
}

.add-content-list-item-button {
  position: sticky;
  top: 0;
  margin-bottom: 2em;
}
