.add-map-button {
  text-align: center;
  margin-bottom: 2em;
}

.card {
  text-align: center;
  background-color: black;
}

.delete-map-icon {
  text-align: right;
}
