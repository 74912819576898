.table-cell {
  vertical-align: middle !important;
}

.add-tip-button {
  text-align: center;
  margin-bottom: 2em;
}

select {
  min-width: 150px;
}

.dot {
  position: absolute;
  height: 7px;
  width: 7px;
  background: red;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.dot.red {
  background: red;
}

.dot.blue {
  background: blue;
}

.clip {
  position: absolute;
  border: 3px solid green;
}

.weapon-group {
  padding: 0 25px 0 15px;
  margin-bottom: 20px;
}

.weapon-group h6 {
  margin-bottom: 20px;
}

.weapon-item {
  height: 40px;
  width: 200px;
  display: flex;
  align-items: center;
  margin: 10px;
  cursor: pointer;
}

.weapon-item:hover svg path,
.weapon-item.selected svg path {
  fill: #182338;
}

.weapon-item:hover h6,
.weapon-item.selected h6 {
  color: #182338;
  font-weight: bold;
}

.weapon-item h6 {
  padding: 0;
  margin-bottom: 0;
  color: #73767d;
}

.weapon-name {
  margin-left: 20px;
}

.map-frame-outer {
  width: 100%;
  max-width: 600px;
}

.map-frame {
  position: relative;
  height: 0;
  padding-bottom: 100%;
}

.map-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  cursor: crosshair;
}

.control-map-container {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.switch-control-form {
  border: 1px solid #73767d;
  border-radius: 5px;
  padding: 10px 15px 2px;
  margin-bottom: 20px;
}

.radio input {
  margin-right: 10px;
}

.color-red {
  color: red;
}

.color-blue {
  color: blue;
}

.wrong-text {
  margin: 20px 0;
  color: red;
}
