.table-cell {
  vertical-align: middle !important;
}

.add-tip-button {
  text-align: center;
  margin-bottom: 2em;
}

.map-frame-outer {
  max-width: 600px;
}
.map-frame {
  position: relative;
  height: 0;
  padding-bottom: 100%;
}
.map-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  cursor: crosshair;
}
.dot {
  position: absolute;
  height: 7px;
  width: 7px;
  background: red;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.map-list {
  display: flex;
}
.map-list button {
  border: none;
  outline: none;
  padding: 4px 16px;
  cursor: pointer;
}
.map-list button.active {
  background: red;
  color: white;
}
.radio-select {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.radio-select--btn {
  display: flex;
  align-items: center;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0 1rem;
  border: 1px solid #ced4da;
  cursor: pointer;
}
.radio-select--btn.checked {
  background: #e7eaec;
}
.radio-select label {
  display: block;
  margin: 0;
  margin-left: 0.5rem;
  padding-top: 0.125rem;
  cursor: pointer;
}
.ytplayer {
  margin-top: 1rem;
  border-radius: 5px;
  box-shadow: 0 14px 16px rgb(0 0 0 / 36%);
}
.yt-id-exmaple {
  color: rgba(27, 40, 56, 0.75);
}
.yt-id-exmaple span {
  text-decoration: underline;
  font-weight: 800;
  color: black;
}
