.table-cell {
  vertical-align: middle !important;
}

.edit-icon {
  color: #000000;
}

.delete-icon {
  color: #ff0000;
}

.add-agent-button {
  text-align: center;
  margin-bottom: 2em;
}

.agent-ability {
  box-sizing: border-box;
  padding: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgb(0 0 0 / 0.03);
  border-radius: 5px;
}
.agent-ability:not(:last-of-type) {
  margin-bottom: 2rem;
}
